import React from 'react'

import CancelLink from './CancelLink';

import strings from '../language/strings';

import hint2_yes_m from '../assets/img/angle-good-m.jpg';
import hint2_no_m from '../assets/img/angle-bad-m.jpg';
import hint2_yes_w from '../assets/img/angle-good-w.jpg';
import hint2_no_w from '../assets/img/angle-bad-w.jpg';

//strings.setLanguage('sv');

class Hint2 extends React.Component {

    constructor(props) {
        super(props);

        if( this.props.getParentState('param_gender') == 'MAN' ){

            this.state = { 
                hint2_no: hint2_no_m,
                hint2_yes: hint2_yes_m,
    
            }

        }
        else{

            this.state = { 
                hint2_no: hint2_no_w,
                hint2_yes: hint2_yes_w,
    
            }
        }

    }


    Continue = e => {
        e.preventDefault();
        this.props.goToStep( 'step1' ); 

    }

    render(){


        return(
            <div className="app-inner">
    
                <div className="app-top">
    
                    <CancelLink url={ this.props.return_url} />
    
                    <h2>{strings.hint2}</h2>
    
                    <div className="hint">
                        <div className="hint__item hint__item--no">
                            <img className="hint__photo" src={this.state.hint2_no} width="" height="" alt="" />
                        </div>
                        <div className="hint__item hint__item--yes">
                            <img className="hint__photo" src={this.state.hint2_yes} width="" height="" alt="" />
                        </div>
                    </div>
    
    
                </div>
                
                <div className="app-bottom">
                    <button className="button button--submit" onClick={ this.Continue }>{strings.start_startbutton}</button>
                </div>
    
            </div>

         )
    }


}

export default Hint2