import React from 'react';
import CancelLink from './CancelLink';

import shape_round from '../assets/img/shape_round.svg';
import shape_oval from '../assets/img/shape_oval.svg';
import shape_square from '../assets/img/shape_square.svg';
import shape_triangle from '../assets/img/shape_triangle.svg';
import strings from '../language/strings';

strings.setLanguage('sv');

class Step1Shape extends React.Component {

    constructor(props) {
        super(props);
        this.state = { shape: this.props.getParentState('tag_faceshape') }
    }

    setShape( e, shape ){
        e.preventDefault();
        this.setState( {shape: shape } );


        //change state in parent
        this.props.handleChange( 'tag_faceshape', shape );
       
    }

    Continue = e => {
        e.preventDefault();

        console.log(this.state);
    
    
        if( !this.props.getParentState('tag_faceshape').length ){
            alert(strings.please_select);
        }
        else{
           this.props.goToStep( 'step2' );
        }


    }
    
    render(){

       return(
            <div className="app-inner">
                
                <div className="app-top">

                    <CancelLink url={ this.props.return_url} />

                    <h2>{strings.shape_hint} <strong>{strings[this.state.shape]}</strong></h2>

                    <p>{strings.shape_instructions}</p>


                    <form className="option-form option-form--icons">

                        <button 
                        className={'ROUND' === this.state.shape ? 'selected' : ''}
                        onClick={ e => this.setShape(e, 'ROUND') }
                        >
                        <img src={shape_round} width="105" height="105" alt={strings.ROUND} />
                        {strings.ROUND}
                        </button>

                        <button
                        className={'TRIANGLE' === this.state.shape ? 'selected' : ''}
                        onClick={ e => this.setShape(e, 'TRIANGLE') }
                        >
                        <img src={shape_triangle} width="105" height="105" alt={strings.TRIANGLE} />
                        {strings.TRIANGLE}
                        </button>

                        <button
                        className={'OVAL' === this.state.shape ? 'selected' : ''}
                        onClick={ e => this.setShape(e, 'OVAL') }
                        >
                        <img src={shape_oval} width="105" height="105" alt={strings.OVAL} />
                        {strings.OVAL}
                        </button>

                    
                        <button 
                        className={'SQUARE' === this.state.shape ? 'selected' : ''}
                        onClick={ e => this.setShape(e, 'SQUARE') }
                        >
                        <img src={shape_square} width="105" height="105" alt={strings.SQUARE}  />
                        {strings.SQUARE}
                        </button>


                    </form>

                </div>

                <div className="app-bottom">

                    <button className="button button--submit" onClick={ this.Continue }>{strings.continue}</button>

                </div>
            </div>
        )


    }
   
}

export default Step1Shape