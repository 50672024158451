import React from 'react'
import Confetti from 'react-confetti'
import ShareResults from './ShareResults'
import Loader from './Loader'
import CancelLink from './CancelLink';

import { LOCAL_API_BASE } from "../utils/constants";
import strings from '../language/strings'
import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers'

class Results extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            current_image : 0,
            reveal_sharer: false,
            loading: true,
            result_image: false,  
        }
    }

    componentDidMount(){

        let args = {
            tag_gender : this.props.getParentState('tag_gender'),
            tag_race : this.props.getParentState('tag_race'),
            tag_age : this.props.getParentState('tag_age'),
            tag_faceshape : this.props.getParentState('tag_faceshape'),
            tag_length : this.props.getParentState('tag_length'),
            tag_look : this.props.getParentState('tag_look'),
            tag_styling : this.props.getParentState('tag_styling')
        };
        

        this.fetch_image();
        

    }

    fetch_image(){

        const api_url = LOCAL_API_BASE + '/faceswap.php';

        let data = this.props.getParentFullState();

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( data ),
        };

        fetch(api_url, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data);

            if( data.success ){

                if( data.s3_image_key ){
                    this.props.addSwappedImage( data.s3_image_key );
                    this.props.handleChange( 'current_swapped_image_key',  data.s3_image_key );
                }

                this.setState( { 
                    loading: false,
                    result_image: data.image
                });
    

                
            }

            else{
                this.props.goToStep( 'error' );
            }

           
        })
        .catch((error) => {
            //console.error('Error:', error);

            //console.error('Faceswap request error:', error);
            this.props.goToStep( 'error' );
            
    
        });



    }

    restart = e => {
        e.preventDefault();
    
        this.props.goToStep( 'start' );
    
    }

    happy = e => {

        e.preventDefault();

    
        this.props.handleChange( 'happy', true );

        this.setState({ reveal_sharer: true});

        this.props.createLog( true );
    

        
        
    }

    notHappy = e => {

        e.preventDefault();

        this.props.createLog( false );
        this.props.goToStep( 'step3' );
        
    }

    
    
    render(){

       if( this.state.loading ){
           return(
            <div className="app-inner">
                <div className="app-top">

                    <CancelLink url={ this.props.return_url} />

                    <h2>Behandlar bilder...</h2>
                </div>
                <div className="app-loader">
                    <Loader />
                </div>
            </div>
           )
       }


       else if( this.state.result_image ){

            const { reveal_sharer } = this.state;

            const confetti_colors = ['#000000','#ffffff'];


            return(
                <div className="app-inner app-inner--results">

                    <CancelLink url={ this.props.return_url} />

                    <ShareResults
                        goToStep={ this.props.goToStep }  
                        restartFresh = { this.props.restartFresh } 
                        reveal_sharer={this.state.reveal_sharer}
                        return_url = { this.props.return_url }
                    />

                    <div>
                        <img src={this.state.result_image} />
                    </div>


                    <Confetti recycle={false} numberOfPieces="200" colors={confetti_colors} />

                
                    <div className={reveal_sharer ? 'results-cta results-cta--hidden' : 'results-cta'}>
                        <label>{strings.results_happyquestion}</label>
                        <div className="results-cta__buttons">
                            <button className="button-yes" onClick={ this.happy }>{strings.yes} 👍</button>
                            <button className="button-no" onClick={ this.notHappy }>{strings.no} 👎</button>
                        </div>
                    </div>


                </div>

                

            )
            
       }
       else{
            return(
                <div>Processing...</div>
            ) 
       }
       
      


    }
   
}

export default Results