import React from 'react'

import CancelLink from './CancelLink';

import { history_url } from '../utils/constants';

import strings from '../language/strings';

import tut1 from '../assets/img/selfie-tutorial-1.svg';
import tut2 from '../assets/img/selfie-tutorial-2.svg';
import tut3 from '../assets/img/selfie-tutorial-3.svg';


//strings.setLanguage('sv');

class Start extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            historylink: false, 
        }


    }

    componentDidMount(){
        let clientID = this.props.getParentState('clientID');

        const queryParams = new URLSearchParams(window.location.search);
        const query_clientID = queryParams.get('clientID');
            
        if( query_clientID ){
            clientID = query_clientID;
        }

        if( clientID && clientID != '' ){
            let link = history_url + '?clientID=' + clientID;
            this.setState({ historylink: link });
            console.log( history_url + '?clientID=' + clientID );
        }

    }


    Continue = e => {
        e.preventDefault();
        this.props.goToStep( 'hint1' ); 

    }

    render(){

    
        

        return(
            <div className="app-inner">
    
                <div className="app-top">
    
                    <CancelLink url={ this.props.return_url} />
    
                    <dl className="instructions">
    
                        <dt><div><img src={tut1} width="63" height="63" alt="" /></div></dt>
                        <dd>{strings.start_instructions_1}</dd>
    
                        <dt><div><img src={tut2} width="63" height="63" alt="" /></div></dt>
                        <dd>{strings.start_instructions_2}</dd>
    
                        <dt><div><img src={tut3} width="63" height="63" alt="" /></div></dt>
                        <dd>{strings.start_instructions_3}</dd>
    
                    </dl>
    
                    <p>
                        {strings.start_intro}
                    </p>
    
    
                </div>
                
                <div className="app-bottom">
                    <button className="button button--submit" onClick={ this.Continue }>{strings.start_startbutton}</button>
                     {/*
                    {this.state.historylink && (
                        <a href={this.state.historylink} className="button button--black button--margintop">{ strings.show_history }</a>
                    )} */}
                </div>
    
            </div>

         )
    }


}

export default Start