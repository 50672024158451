import React from 'react'
import Loader from './Loader'
import CancelLink from './CancelLink';

import strings from '../language/strings'

class Step3Suggestions extends React.Component {

    constructor(props) {
        super(props);

        if( !this.props.getParentState('tag_gender').length || !this.props.getParentState('tag_length').length || !this.props.getParentState('tag_faceshape').length  ){
            this.props.goToStep( 'error' );
        }

        const image_path = window.location.protocol + "//" + window.location.host + '/haircutimages/' + this.props.getParentState('tag_gender') + '/' + this.props.getParentState('tag_length') + '/' + this.props.getParentState('tag_faceshape') + '/';

        const suggestion_images = [];

        for (let i = 1; i <= 4; i++) {
            suggestion_images.push( {'id' : i,  'url' : image_path + i + '.jpg'} );
        } 

        console.log(suggestion_images);

        this.state = { 
            selected_image: '',
            suggestion_images: suggestion_images,
            loading: true,  
        }



    }

    componentDidMount(){

        const that = this;

        if( !this.props.getParentState('first_try') ){
            that.setState( { loading: false } );
        }

        else{
            setTimeout(function(){

                that.props.handleChange( 'first_try', false );
                that.setState( { loading: false } );
    
            }, 500);
        }
        
    }

    setImage( e, item ){
        e.preventDefault();
        this.setState( {selected_image: item.id } );
        console.log(item);



        //change state in parent
        this.props.handleChange( 'selected_image', item );
       
    }

    Continue = e => {

        e.preventDefault();

        console.log( this.props.getParentState('selected_image') );

        if( !this.props.getParentState('selected_image') ){
            alert(strings.please_select);
        }
        else{
        
           
             this.props.goToStep( 'results' );
        
           
        }
    }

    
    render(){

        

       if( this.state.loading ){
           return(
            <div className="app-inner">
                <div className="app-top">

                    <CancelLink url={ this.props.return_url} />


                </div>
                <div className="app-loader">
                    <Loader />
                </div>
            </div>
           )
       }


       else{

    
            const images = this.state.suggestion_images;
            console.log( images );

            return(
                <div className="app-inner app-inner--suggestions">

                    <div className="app-top">

                    <CancelLink url={ this.props.return_url} />

                    <h2>{strings.suggestions_heading}</h2>

                    <form className="option-form option-form--images">

                
                        {this.state.suggestion_images.map(item => (

                            <button
                            key={item.id}
                            className={item.id === this.state.selected_image ? 'selected' : ''}
                            onClick={ e => this.setImage(e, item) }
                            >
                                <img src={item.url} alt="Suggestion" />
                            </button>

                        ))} 
                   

                        {this.state.suggestion_images.map(function(value, index, item){
                            <button onClick={ e => this.setImaage(e, item) }>{value}</button>
                        })}
                 

                    </form>

                    </div>

                    <div className="app-bottom">

                        <button className="button button--submit" onClick={ this.Continue }>{strings.continue}</button>

                    </div>


                </div>

                

            )
            
       }
       
      


    }
   
}

export default Step3Suggestions